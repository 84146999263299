<template>
  <div id="app">
    <div :class="[{'isDesktop': isDesktop}]">
      <div class="columns is-full is-gapless">
        <nav  :class="['navbar', isDesktop ? 'is-2 column' : 'is-fixed-top', ]">
          <Sidebar />
        </nav>
        <div :class="['column', isDesktop ?  'is-10' : 'is-12']" id="content">
          <perfect-scrollbar ref="scroll" v-if="isDesktop">
 <transition name="scale" mode="out-in">
            <router-view />
          </transition>
</perfect-scrollbar>
         <div v-else>
 <transition name="scale" mode="out-in">
            <router-view />
          </transition>
         </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
require('./assets/dot.png');
import Sidebar from "@/components/Sidebar";
export default {
  name: "Home",
  components: {
    Sidebar,
    PerfectScrollbar
  },
  watch: {
    isDesktop: function() {
      this.checkDesktop();
    }
  },
  methods: {
    checkDesktop() {
      // console.log("this.isDesktop",this.isDesktop);
      // if (this.isDektop) {
      //   document.documentElement.classList.remove('has-navbar-fixed-top');
      //   console.log("class removed", document.documentElement.classList);
      // } else {
      //   document.documentElement.classList.add('has-navbar-fixed-top');
      //   console.log("class added", document.documentElement.classList);
      // }
      if (this.isDesktop) {
        // console.log("trueee");
        document.documentElement.classList.remove('has-navbar-fixed-top');
      } else document.documentElement.classList.add('has-navbar-fixed-top');
      // console.log("over");
    }
  },
  mounted() {
    this.checkDesktop();
  }
};
</script>
<style lang="scss">
body {
  margin: 0;
}
.isDesktop {
  .ps {
    height: 100vh;
  }
}
.ps {
  height: calc(100vh - 3.25rem);
  .ps__rail-y:hover > .ps__thumb-y {
    background-color: $primary;
  }
  .ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: $primary;
  }
  .ps__thumb-y {
    background-color: $primary;
  }
}
</style>
<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}

.scale-enter,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
.isDesktop {
  .navbar {
    background: repeat center/11px url("~@/assets/dot.png");
  }
}
.navbar {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.08);
}
#content {
  background-color: #f6f6f6;
}
// #content {
//   background-color: #f6f6f6;
//   /* Hide scrollbar for Chrome, Safari and Opera */
//   &::-webkit-scrollbar {
//     display: none;
//   }

//   /* Hide scrollbar for IE, Edge and Firefox */
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// }

</style>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"/>
