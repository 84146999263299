<template>
  <figure class="image" :class="sizeCss">
    <img class="is-rounded img" src="@/assets/profile-pic.jpeg" />
  </figure>
</template>

<script>
export default {
  name: "ProfilePhoto",
  props: {
    size: Number,
  },
  computed: {
    sizeCss() {
      return `is-${this.size}x${this.size}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  border: 1px solid rgba(0, 0, 0, 0.16);
  padding: 5px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  &.is-140x140 {
    height: 140px;
    width: 140px;
  }
  &.is-150x150 {
    height: 150px;
    width: 150px;
  }
}
</style>
