<template>
  <div :class="['home',{'isDesktop': isDesktop}]" >
    <div
      class="overlay is-flex-direction-column is-flex is-justify-content-center"
    >
      <div class="center">
        <ProfilePhoto :size="150" />
        <div id="typewriter"></div>
        <div class="is-flex is-justify-content-center">
          <div class="line"></div>
        </div>
        <div class="is-flex is-justify-content-center">
          <div :class="['buttons', { 'are-large' : isDesktop}]">
            <button :class="[ 'button is-primary']" @click="goToContact"><a href="Lavinia-Lattanzio-Resume.pdf" target="_blank">
  Download CV
</a></button>
            <button :class="['button is-primary']" @click="goToContact">
              Contact me
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Typewriter from "typewriter-effect/dist/core";

import ProfilePhoto from "@/components/ProfilePhoto";
export default {
  name: "Home",
  components: {
    ProfilePhoto,
  },
  methods: {
    typewrite() {
      var typewriter = new Typewriter("#typewriter", {
        loop: true,
        delay: 40,
        deleteSpeed: 20,
      });
      let strings = [
        "Lavinia Lattanzio",
        "a UX/UI Designer",
        "living in UK",
        "a graduate in cognitive sciences",
        "from Italy",
        "a graduate in communication sciences too",
      ];
      typewriter
        .pauseFor(1000)
        .typeString("Hello!")
        .pauseFor(2000)
        .deleteChars(6)
        .typeString("I'm ");

      strings.forEach((string) => {
        let stringWithSpan = `<span class="colored">${string}</span>`;
        typewriter
          .typeString(stringWithSpan)
          .pauseFor(1000)
          .deleteChars(string.length);
      });

      typewriter.start();
    },
    goToContact() {
      this.$router.push({ name: "ContactMe" });
    },
  },
  mounted() {
    this.typewrite();
  },
};
</script>
<style lang="scss">
#typewriter {
  .colored {
    color: $primary;
  }
}
</style>
<style lang="scss" scoped>
.home {
   background-image: url("~@/assets/pexels-pixabay-273230.jpg");
  background-size: cover;
  height: 100%;
    height: calc(100vh - 3.25rem);
  
  &.isDesktop {
    height: 100vh;
  #typewriter {
    font-size: 3rem;
    margin-top: 6rem;
    height: 25vh;
  }
  }
  .overlay {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.75);
}
}
#typewriter {
  margin-top: 3rem;
  height: 30vh;
  font-size: 2.5rem;
  font-weight: 800;
  position: relative;
  z-index: 10;
  color: #fff;
  .colored {
    color: $primary;
  }
}
.line {
  background-color: white;
  width: 26px;
  height: 4px;
  margin: 30px;
  border-radius: 4px;
}
button a {
  color: white;
  &:hover, &:active,&:focus, &:visited {
    color: white;
  }
}
</style>
