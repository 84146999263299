<template>
  <div class="menu" :class="{'isDesktop': isDesktop}">
    <div class="navbar-burger burger"  :class="{'is-active': isOpen}" id="burger" @click="isOpen = !isOpen">
      <span></span>
      <span></span>
      <span></span>
    </div>
     <div class="navbar-menu" :class="{'is-active': isOpen}">
       <div class="navbar-start" v-if="isDesktop">
          <ProfilePhoto :size="140" />
           <div class="social">
        <Social />
      </div>
       </div>
       <div class="navbar-end">
        <router-link @click.native="isOpen = !isOpen" class="navbar-item menu-link" :to="{ name: 'Home' }">Home</router-link>
        <router-link @click.native="isOpen = !isOpen" class="navbar-item menu-link" :to="{ name: 'AboutMe' }"
          >About me</router-link
        >
        <router-link @click.native="isOpen = !isOpen" class="navbar-item menu-link" :to="{ name: 'Resume' }"
          >Resume</router-link
        >
        <router-link @click.native="isOpen = !isOpen" class="navbar-item menu-link" :to="{ name: 'CaseStudies' }"
          >Case studies</router-link
        >
        <router-link @click.native="isOpen = !isOpen" class="navbar-item menu-link" :to="{ name: 'ContactMe' }"
          >Contact me</router-link
        >
       </div>
   </div>

    <!-- <div class="is-flex is-justify-content-center is-flex-direction-column">
      <ProfilePhoto :size="140" />
      <h2 class="name">Lavinia Lattanzio</h2>
      <div class="social">
        <Social />
      </div>
    </div> -->
  </div>
</template>

<script>
import ProfilePhoto from "@/components/ProfilePhoto";
import Social from "@/components/Social";

export default {
  data() {
    return {
      isOpen: false
    }
  },
  components: {
    ProfilePhoto,
    Social,
  },
};
</script>
<style lang="scss" scoped>
.menu {
  // margin-top: 30px;
  a {
    text-transform: uppercase;
    color: $secondary;
    &:hover {
      color: $primary;
    }
    &.router-link-exact-active {
      color: $primary;
    }
  }
  &.isDesktop {
    .navbar-menu {

      flex-direction: column;
      .navbar-start {
            margin: 2rem auto!important;
            .social {
              margin: 2rem 0;
            }
      }
      .navbar-start, .navbar-end {
        flex-direction: column;
        margin: auto;
      }
    }
  }
}
.name {
  color: secondary;
  font-weight: 700;
  font-size: 21px;
  text-transform: capitalize;
}
h2 {
  margin-top: 22px;
  margin-bottom: 12px;
}
.menu-link {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  color: $grey;
  margin: 3px;
  letter-spacing: 0.7px;
}
.navbar-item{
  &:hover, &:focus, &:active {
    background-color: unset!important;
  }
}

</style>
