import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/aboutme",
    name: "AboutMe",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutMe.vue"),
  },
  {
    path: "/resume",
    name: "Resume",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Resume.vue"),
  },
  {
    path: "/contact",
    name: "ContactMe",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactMe.vue"),
  },
  {
    path: "/case-studies",
    name: "CaseStudies",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CaseStudies.vue"),
    // children: [
    //   {
    //     path: "sugarFairy",
    //     name: "SugarFairy",
    //     component: () =>
    //       import(/* webpackChunkName: "about" */ "../views/CaseStudies/SugarFairy.vue"),
    //   },
    // ]
  },
  {
    path: "/case-studies/sugarFairy",
    name: "SugarFairy",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/CaseStudies/SugarFairy.vue"
      ),
  },
  {
    path: "/case-studies/airFuels",
    name: "AirFuels",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/CaseStudies/AirFuels.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(() => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
})

export default router;
