import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// require('@/assets/main.scss');
// import vueBulmaComponents from 'vue-bulma-components'
// Vue.use(vueBulmaComponents)

//Font-awesome
import { library } from "@fortawesome/fontawesome-svg-core";
// import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// library.add(faUserSecret);
import { faFontAwesome } from "@fortawesome/free-brands-svg-icons";

library.add(faFontAwesome);

Vue.component("font-awesome-icon", FontAwesomeIcon);

//Breakpoint
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    mobile: 450,
    tablet: 900,
    laptop: 1024,
    desktop: Infinity,
  }
})
//Global components
Vue.component("Page", () => import("./components/Page.vue"));
Vue.config.productionTip = false;

Vue.mixin({
  computed: {
    isMobile() {
      return this.$mq === "mobile";
    },
    isTablet() {
      return this.$mq === "tablet";
    },
    // isLaptop() {
    //   return this.$mq === "laptop";
    // },
    // isDesktop() {
    //   return this.$mq === "desktop";
    // },
    isDesktop() {
      return this.$mq === "laptop" || this.$mq === "desktop";
    }
}
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
